<template>
  <div
    v-if="details"
    class="bg-primary-white lg:p-6 p-3.5 border border-primary-gray-200 rounded-lg text-xs md:text-base"
  >
    <div class="py-3 font-inter">
      <div class="flex flex-wrap" :class="customClasses">
        <div v-for="(detail, key, index) in details" :key="index" class="w-full md:w-1/2">
          <div v-if="!excludeKeys.includes(key)" class="flex flex-1 gap-2">
            <div class="flex-1">
              <span>{{ toPascalCase(key) }}:</span>
            </div>
            <div class="text-primary-grey-light capitalize flex-1">
              {{ detail || detail === 0 ? detail : GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import { toPascalCase } from '@utils/generalUtil'
export default {
  props: {
    excludeKeys: {
      type: Array,
      default: () => ['id'],
    },
    details: {
      type: Object,
      default: () => {},
    },
    customClasses: {
      type: String,
      default: 'md:flex-row flex-col',
    },
  },
  data() {
    return {
      GENERAL_CONSTANTS,
    }
  },
  methods: {
    toPascalCase,
  },
}
</script>
