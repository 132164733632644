<template>
  <div>
    <div class="py-5 font-medium font-roboto text-label-text" :class="headingSize">
      {{ heading }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    heading: {
      type: String,
      default: 'Heading',
    },
    headingSize: {
      type: String,
      default: 'text-xl',
    },
  },
}
</script>
