<template>
  <div>
    <ValidationObserver @submit="assginedAssignmentHandler">
      <UiModalContainer
        footer-classes="flex justify-end gap-3"
        :modal-show="modal"
        :modal-width="50"
        @handleClick="handleClick"
      >
        <template v-slot:header>
          <span>
            <span>Assign Assignment</span>
          </span>
        </template>
        <div>
          <div class="px-6">
            <DetailCard :details="assignment" class="mt-5" />
          </div>
          <div class="py-6 px-6">
            <UiSingleSelect
              v-if="!currentSectionScope"
              v-model="sectionId"
              title="Section"
              :options="sectionsList"
              label="title"
              reduce="id"
              class="flex-1"
              rules="required"
              :hide-un-select-icon="true"
              @change="initData(sectionId)"
            />
            <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
              <UiSingleSelect
                v-model="assignedData.student_id"
                title="STUDENTS"
                :options="studentsList"
                label="full_name"
                reduce="id"
                class="flex-1"
                rules="required"
              />
              <UiSingleSelect
                v-model="assignedData.evaluator_id"
                title="EVALUATOR"
                :options="teachersList"
                label="full_name"
                reduce="id"
                class="flex-1"
                rules="required"
              />
            </div>
            <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
              <UiDatePicker
                v-model="assignedData.due_date"
                title="Due Date"
                :date-mode="DATE_MODE.END_DATE_TIME"
                class="flex-1"
                rules="required"
              />
            </div>
          </div>
        </div>
        <template v-slot:footer>
          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
            @click="emit('toggle')"
          >
            Cancel
          </button>
          <button
            class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
          >
            <span v-if="isLoading">
              <Loader />
            </span>
            <span v-else>Assign</span>
          </button>
        </template>
      </UiModalContainer>
    </ValidationObserver>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'

// Components
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import { DATE_MODE } from '@src/constants/date-time-constants.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { buildWhereQuery } from '@src/utils/filters/index'

// Store
import { useStore } from 'vuex'
const store = useStore()

// Props
const { assignment, id, modal } = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  assignment: {
    type: Object,
    default: () => {},
  },
  id: {
    type: String,
    default: '',
    required: true,
  },
})

// Emits
const emit = defineEmits(['toggle'])

// States
const assignedData = ref({
  student_id: '',
  evaluator_id: '',
  due_date: '',
})
const isLoading = ref(false)
const studentsList = ref([])
const teachersList = ref([])
const sectionsList = ref([])
const sectionId = ref('')

// Computed
const currentSectionScope = computed(() => store.state.layout.currentSectionScope)
const currentClassScope = computed(() => store.state.layout.currentClassScope)
const classesList = computed(() => store.state.classes.classesList)
// Mounted
onMounted(() => {
  if (currentSectionScope.value) initData()
  else
    getClassesList().then(() => {
      setSection(currentClassScope.value.id)
    })
})
// Methods
const setSection = (id) => {
  classesList.value.forEach((classItem) => {
    if (classItem.id === id) {
      sectionsList.value = classItem.sections
    }
  })
}

const assginedAssignmentHandler = () => {
  isLoading.value = true
  const data = { ...assignedData.value, ...{ assignment_id: id } }
  assignAssignmentToStudent(data)
    .then(() => {
      store.commit('toast/NEW', { message: 'Assigned Successfully', type: 'success' })
      handleClick('close')
    })
    .finally(() => {
      isLoading.value = false
    })
}

const handleClick = (eventName) => {
  switch (eventName) {
    case 'close':
      emit('toggle')
      break
  }
}
const initData = (secId) => {
  assignedData.value.student_id = ''
  assignedData.value.evaluator_id = ''
  studentsList.value = []
  teachersList.value = []

  let payload = {}
  if (secId)
    payload['$where'] = {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', secId),
    }

  Promise.all([getTeacherWithFilters(payload), getStudentsWithFilters(payload)]).then((values) => {
    teachersList.value = values[0]?.data?.records
    studentsList.value = values[1]?.records
  })
}
// Actions
const assignAssignmentToStudent = (data) =>
  store.dispatch('assignment/assignAssignmentToStudent', data)
const getTeacherWithFilters = (data) => store.dispatch('teacher/getTeacherWithFilters', data)
const getStudentsWithFilters = (data) => store.dispatch('student/getStudentsWithFilters', data)
const getClassesList = (data) => store.dispatch('classes/getClassesList', data)
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
