<template>
  <ValidationObserver @submit="assginedAssignmentHandler">
    <UiModalContainer
      footer-classes="flex justify-end gap-3"
      :modal-show="modal"
      :modal-width="50"
      @handleClick="handleClick"
    >
      <template v-slot:header>
        <span>
          <span>Assign Assignment to</span>
        </span>
      </template>
      <div>
        <div class="px-6">
          <DetailCard :details="assignment" class="mt-5" />
        </div>
        <div class="py-6 px-6">
          <UiSingleSelect
            v-if="!currentSectionScope"
            v-model="sectionId"
            title="Section"
            :options="sectionsList"
            label="title"
            reduce="id"
            class="flex-1"
            rules="required"
            :hide-un-select-icon="true"
            @change="initData(sectionId)"
          />
          <div class="flex flex-col sm:flex-row gap-4 lg:gap-5">
            <UiDatePicker
              v-model="assignedData.dueDate"
              title="Due Date"
              :date-mode="DATE_MODE.END_DATE_TIME"
              class="flex-1"
              rules="required"
              @change="updateDueDateHandler(assignedData.dueDate)"
            />
            <UiSingleSelect
              v-model="assignedData.evaluator"
              title="EVALUATOR"
              :options="objectDeepCopy(teachersList)"
              :select-object-without-reduce-key="true"
              label="full_name"
              class="flex-1"
              rules="required"
              @change="updateEvaluator(assignedData.evaluator)"
            />
          </div>
          <div>
            <Heading heading="Assign To" />
            <Loader v-if="isStudentLoading" :content="true" class="pb-40" />
            <div
              v-else-if="currentStudentsList.length && !isStudentLoading"
              class="rounded-lg border relative border-primary-grey bg-white"
            >
              <div
                id="table-container"
                :class="[leftBlurArea, rightBlurArea]"
                class="overflow-x-auto rounded-lg"
                @scroll.passive="scrollDetect"
              >
                <table class="w-full table-auto whitespace-nowrap">
                  <thead class="bg-bright-gray">
                    <tr class="text-center">
                      <th
                        v-for="(head, index) in tableHeaders"
                        :key="index"
                        class="font-roboto font-medium text-base text-text-color py-5 rtl:text-right rtl:pr-4 rtl:pl-0 ltr:pl-4 ltr:pr-0"
                      >
                        <span
                          class="rtl:border-l ltr:border-r border-primary-grey w-full block rtl:pl-3 ltr:pr-3"
                        >
                          {{ head }}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(student, index) in currentStudentsList"
                      :key="index"
                      class="border-t border-primary-grey text-center"
                    >
                      <td
                        class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4"
                      >
                        <span class="font-roboto font-normal text-sm cursor-pointer">
                          <SingleUserDisplay :user="student" label="full_name" :image="true" />
                        </span>
                      </td>
                      <td
                        :key="index"
                        class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4 relative"
                      >
                        <span class="font-roboto font-normal text-sm cursor-pointer">
                          <span
                            v-if="student.is_assigned"
                            s
                            class="font-roboto font-normal text-sm"
                          >
                            {{ formatDate(student.already_assigned_due_date) }}
                          </span>
                          <UiDatePicker
                            v-else
                            v-model="student.due_date"
                            title="Due Data"
                            class="flex-1"
                            :date-mode="DATE_MODE.END_DATE_TIME"
                            :no-error="true"
                          />
                        </span>
                      </td>
                      <td
                        class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4 relative"
                      >
                        <span class="font-roboto font-normal text-sm cursor-pointer">
                          <span v-if="student.is_assigned" class="font-roboto font-normal text-sm">
                            {{ student.already_assigned_evaluator }}
                          </span>
                          <UiSingleSelect
                            v-else
                            v-model="student.evaluator"
                            :hide-un-select-icon="true"
                            title="EVALUATOR"
                            :options="objectDeepCopy(teachersList)"
                            label="full_name"
                            :disabled="!!!assignedData.evaluator"
                            :select-object-without-reduce-key="true"
                            class="flex-1"
                            :no-error="true"
                          />
                        </span>
                      </td>
                      <td
                        class="ltr:border-r rtl:border-l border-primary-grey rtl:text-right px-3 py-4"
                      >
                        <UiCheckbox
                          v-model="student.is_marked"
                          :no-error="true"
                          class="flex self-center"
                          :disabled="student.is_assigned"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              v-if="studentsCount > GENERAL_CONSTANTS.RECORD_LIMIT"
              v-show="!isStudentLoading"
              :record-limit="GENERAL_CONSTANTS.RECORD_LIMIT"
              :max-range="paginationCounts(studentsCount, GENERAL_CONSTANTS.RECORD_LIMIT)"
              @filterRecord="filterRecords"
            />
          </div>
        </div>
      </div>
      <template v-slot:footer>
        <div
          class="flex items-center w-full"
          :class="!isVisitAllPages ? 'justify-between' : 'justify-end'"
        >
          <div
            v-if="!isVisitAllPages"
            class="xl:text-lg sm:text-base text-sm text-primary-green flex self-start"
          >
            Note: Visit all pages before submitting
          </div>
          <div class="flex gap-2 items-center">
            <button
              class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
              @click="emit('toggle')"
            >
              Cancel
            </button>
            <button
              class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
              :disabled="!isVisitAllPages"
              :class="!isVisitAllPages && 'disabled-btn e '"
            >
              <span v-if="isLoading">
                <Loader />
              </span>
              <span v-else>Assign</span>
            </button>
          </div>
        </div>
      </template>
    </UiModalContainer>
  </ValidationObserver>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'

// Components
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import { Form as ValidationObserver } from 'vee-validate'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import Heading from '@components/Heading.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import UiCheckbox from '@components/UiElements/UiCheckbox.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'

// Own Module
import { buildWhereQuery } from '@/src/utils/filters/index'
import { DATE_MODE } from '@src/constants/date-time-constants.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { formatDate } from '@utils/moment.util'
import {
  paginationCounts,
  paginationRangeHandler,
} from '@src/components/BaseComponent/pagination.util.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants.js'
import { objectDeepCopy } from '@utils/generalUtil'
// Store
import { useStore } from 'vuex'

const store = useStore()

// Props
const { assignment, id, modal } = defineProps({
  modal: {
    type: Boolean,
    default: false,
  },
  assignment: {
    type: Object,
    default: () => {},
  },
  id: {
    type: String,
    default: '',
    required: true,
  },
})

// Emits
const emit = defineEmits(['toggle'])

// States
const assignedData = ref({
  evaluator: '',
  dueDate: '',
})
const isLoading = ref(false)
const isStudentLoading = ref(false)
const isVisitAllPages = ref(false)
const currentStudentsList = ref([])
const studentsList = ref([])
const studentsCount = ref(0)
const teachersList = ref([])
const sectionsList = ref([])
const sectionId = ref('')
const tableHeaders = ['Student', 'Due Date', 'Evaluator', 'Status']

// Computed
const currentSectionScope = computed(() => store.state.layout.currentSectionScope)
const currentClassScope = computed(() => store.state.layout.currentClassScope)
const classesList = computed(() => store.state.classes.classesList)

// Mounted
onMounted(() => {
  if (currentSectionScope.value) initData()
  else
    getClassesList().then(() => {
      setSection(currentClassScope.value.id)
    })
})

// Watch
watch(
  currentStudentsList,
  () => {
    let count = Math.floor(studentsCount.value / GENERAL_CONSTANTS.RECORD_LIMIT)
    if (studentsList.value.hasOwnProperty(`${count}`)) isVisitAllPages.value = true
  },
  { immediate: true },
)

// Methods

const setSection = (id) => {
  classesList.value.forEach((classItem) => {
    if (classItem.id === id) {
      sectionsList.value = classItem.sections
    }
  })
}

const handleClick = (eventName) => {
  switch (eventName) {
    case 'close':
      emit('toggle')
      break
  }
}
const initData = (secId) => {
  currentStudentsList.value = []
  studentsList.value = []
  teachersList.value = []
  assignedData.value.evaluator = ''
  isStudentLoading.value = true
  let payload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'assignment_id', id),
    },
  }

  if (secId)
    payload['$where'] = {
      ...payload['$where'],
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', secId),
    }

  Promise.all([getTeacherWithFilters(payload), getStudentsWithFilters(payload)]).then((values) => {
    teachersList.value = values[0]?.data?.records
    currentStudentsList.value = objectDeepCopy(tranformStudentData(values[1]?.data?.records))
    studentsList.value = { 0: currentStudentsList.value }
    studentsCount.value = values[1]?.data?.meta?.total_records
    isStudentLoading.value = false
  })
}

const filterRecords = (range) => {
  isStudentLoading.value = true
  let filterStudentsPayload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'assignment_id', id),
    },
  }

  if (sectionId.value)
    filterStudentsPayload['$where'] = {
      ...filterStudentsPayload['$where'],
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'section_id', sectionId.value),
    }

  let paginationPayload = paginationRangeHandler(range)
  let payload = { ...filterStudentsPayload, ...paginationPayload }

  let pageNo = getPageNumberWithRange(paginationPayload)
  let isStudentsListAlreadyExisted = studentsList.value.hasOwnProperty(`${pageNo}`)

  getStudentsWithFilters(payload)
    .then((response) => {
      if (isStudentsListAlreadyExisted) currentStudentsList.value = studentsList.value[`${pageNo}`]
      else {
        let records = objectDeepCopy(tranformStudentData(response?.data?.records))
        currentStudentsList.value = records
        studentsList.value = { ...studentsList.value, ...{ [pageNo]: records } }
      }
      studentsCount.value = response?.data?.meta?.total_records
    })
    .finally(() => {
      isStudentLoading.value = false
    })
}

const tranformStudentData = (records) => {
  return records.map((student) => {
    return {
      id: student.id,
      image: student.image,
      full_name: student.full_name,
      first_name: student.first_name,
      last_name: student.last_name,
      is_assigned: student.is_assigned,
      due_date: student?.due_date || objectDeepCopy(assignedData.value.dueDate) || '',
      evaluator: student?.evaluator || objectDeepCopy(assignedData.value.evaluator) || '',
      already_assigned_due_date: student.due_date,
      already_assigned_evaluator: `${student?.evaluator?.first_name} ${student?.evaluator?.last_name}`,
      is_marked: true,
    }
  })
}

const updateDueDateHandler = (date) => {
  Object.values(studentsList.value)
    .flat()
    .forEach((student) => {
      student.due_date = date
    })
}

const updateEvaluator = (evalulator) => {
  Object.values(studentsList.value)
    .flat()
    .forEach((student) => {
      student.evaluator = objectDeepCopy(evalulator)
    })
}

const getPageNumberWithRange = (paginationRange) => {
  return paginationRange.skip / GENERAL_CONSTANTS.RECORD_LIMIT
}

const assginedAssignmentHandler = () => {
  isLoading.value = true
  const payload = getPayload()
  assignAssignmentToSection(payload)
    .then(() => {
      store.commit('toast/NEW', { message: 'Assigned Successfully', type: 'success' })
      handleClick('close')
    })
    .finally(() => {
      isLoading.value = false
    })
}

const getPayload = () => {
  let students = Object.values(studentsList.value)
    .flat()
    .map((student) => {
      if (!student.is_assigned && student.is_marked)
        return {
          due_date: student.due_date,
          student_id: student.id,
          evaluator_id: student.evaluator.id,
        }
    })
    .filter((item) => !!item)

  return {
    assignment_id: id,
    assignment_students: students,
  }
}

// Actions
const assignAssignmentToSection = (data) =>
  store.dispatch('assignment/assignAssignmentToSection', data)
const getTeacherWithFilters = (data) => store.dispatch('teacher/getTeacherWithFilters', data)
const getStudentsWithFilters = (data) =>
  store.dispatch('assignment/filterAssignmentStudentList', data)
const getClassesList = (data) => store.dispatch('classes/getClassesList', data)
</script>

<style lang="scss" scoped>
.disabled-btn {
  background-color: var(--primary-grey);
  border: none;
}
</style>
