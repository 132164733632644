<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="ASSIGNMENT_DETAILS"
      tooltip-title="ASSIGNMENT_DETAILS"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div v-if="isLoading">
      <Loader :content="true" />
    </div>
    <div v-else class="w-full mt-5">
      <DetailCard :details="assignmentDetails" class="mt-5" />
      <!-- Weblinks -->
      <div class="mt-5">
        <Heading v-if="webLinksData.length" heading="Web Links" />
        <Loader v-if="isWebLinksLoading" :content="true" class="py-20" />
        <TableWrapper v-else-if="webLinksData.length && !isWebLinksLoading" id="webLinksTable">
          <THead>
            <TRHead>
              <TH v-for="(head, index) in webLinksTableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(webLink, index) in webLinksData" :key="index">
              <TD>
                {{ webLink.title || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <a
                  :href="webLink.link"
                  target="_blank"
                  class="cursor-pointer text-primary-purple-600"
                >
                  {{ webLink.link }}
                </a>
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
        <Pagination
          v-if="webLinksCounts > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
          v-show="!isWebLinksLoading"
          :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
          :max-range="paginationCounts(webLinksCounts, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
          @filterRecord="filterWebLinks"
        />
      </div>
      <!-- CheckList -->
      <div class="mt-5">
        <Heading v-if="checkListData.length" heading="Check List" />
        <Loader v-if="isCheckListLoading" :content="true" class="py-20" />
        <div
          v-else-if="checkListData.length && !isCheckListLoading"
          class="text-base bg-white p-5 rounded-lg"
        >
          <div v-for="(checkListItem, index) in checkListData" :key="index" class="mb-5">
            <span class="capitalize">
              {{ `${checkListItem.bullet} ) ` }}{{ checkListItem.title }}
            </span>
            <div
              v-for="(subItem, idx) in checkListItem.assignment_checklist_items"
              :key="idx"
              class="ml-6 mt-3 text-sm"
            >
              <span>{{ `${intToRoman(idx + 1)} ) ` }}{{ subItem.title }}</span>
            </div>
          </div>
        </div>
        <Pagination
          v-if="checkListCounts > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
          v-show="!isCheckListLoading"
          :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
          :max-range="paginationCounts(checkListCounts, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
          @filterRecord="filterCheckList"
        />
      </div>
      <!-- Attachments -->
      <div v-if="attachmentsData.length" class="mt-5">
        <Heading heading="Attachments" />
        <TableWrapper id=" attachmentTable">
          <THead>
            <TRHead>
              <TH v-for="(head, index) in attachmentsTableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(attachment, index) in attachmentsData" :key="index">
              <TD>
                {{ attachment.file_name || GENERAL_CONSTANTS.NOT_APPLICABLE }}
              </TD>
              <TD>
                <a
                  :href="attachment.url"
                  class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 w-8 flex justify-center items-center primary-button mx-auto"
                  download
                >
                  <span>Download</span>
                </a>
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
      </div>
      <!-- Students -->
      <div class="mt-5">
        <Heading heading="Students" />
        <Loader v-if="isStudentsListLoading" :content="true" class="py-20" />
        <TableWrapper v-else-if="studentsList.length && !isStudentsListLoading">
          <THead>
            <TRHead>
              <TH v-for="(head, index) in studentsTableHeaders" :key="index">
                {{ head }}
              </TH>
            </TRHead>
          </THead>
          <TBody>
            <TRBody v-for="(item, index) in studentsList" :key="index">
              <TD>
                <SingleUserDisplay :user="item.student" label="full_name" :image="true" />
              </TD>
              <TD>
                <SingleUserDisplay :user="item.evaluator" label="full_name" :image="true" />
              </TD>
              <TD>
                {{ item.status && transformTextToCapitalize(item.status) }}
              </TD>
            </TRBody>
          </TBody>
        </TableWrapper>
        <NoRecordFound v-else />
        <Pagination
          v-if="studentsCounts > GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
          v-show="!isStudentsListLoading"
          :record-limit="GENERAL_CONSTANTS.MODAL_RECORD_LIMIT"
          :max-range="paginationCounts(studentsCounts, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)"
          @filterRecord="filterStudentsList"
        />
      </div>
      <!-- Settings -->
      <div class="mt-5">
        <Heading heading="Settings" />
        <div class="mt-5 bg-white p-5 rounded-lg">
          <!-- Attachment File Type -->

          <div class="flex justify-between">
            <Heading heading="Submission Description Required" heading-size="text-base" />
            <div>
              {{ getSettingsStatus(settings.submission_description_required) }}
            </div>
          </div>
          <div class="flex justify-between">
            <Heading heading="Assignment Resubmission" heading-size="text-base" />
            <div>
              {{ getSettingsStatus(settings.assignment_resubmission_allowed) }}
            </div>
          </div>
          <div class="flex justify-between">
            <Heading heading="Attachment Required" heading-size="text-base" />

            <div>
              {{
                settings.attachment_required &&
                transformTextToCapitalize(settings.attachment_required)
              }}
            </div>
          </div>
          <div class="flex justify-between">
            <Heading heading="Attachment File Type" heading-size="text-base" />
            <div class="flex flex-col items-end gap-3">
              <div v-for="attachmentType in settings.attachment_file_type" :key="attachmentType">
                {{ attachmentType }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AssignAssignmentToStudentModal
        v-if="showModal"
        :id="assignmentId"
        :modal="showModal"
        :assignment="assignmentDetails"
        @toggle="toggleAssignStudentModal"
      />
      <AssignAssignmentToSectionModal
        v-if="showUiModal"
        :id="assignmentId"
        :modal="showUiModal"
        :assignment="assignmentDetails"
        @toggle="toggleAssignSectionModal"
      />
    </div>
  </div>
</template>

<script setup>
// Vue
import { ref, onMounted, computed, watch } from 'vue'

// Router
import { useRoute } from 'vue-router'
const route = useRoute()

// Store
import { useStore } from 'vuex'
const store = useStore()

import { useRouter } from 'vue-router'
const router = useRouter()

// Components
import TitleWrapper from '@components/TitleWrapper.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import Loader from '@components/BaseComponent/Loader.vue'
import Heading from '@components/Heading.vue'
import DetailCard from '@src/components/UiElements/DetailCard.vue'
import AssignAssignmentToStudentModal from '@views/assignments/modals/AssignAssignmentToStudentModal.vue'
import AssignAssignmentToSectionModal from '@src/router/views/assignments/modals/AssignAssignmentToSection.vue'
import NoRecordFound from '@src/components/BaseComponent/NoRecordFound.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
// Own Module
import { transformTextToCapitalize, intToRoman } from '@src/utils/generalUtil'
import {
  paginationCounts,
  paginationRangeHandler,
} from '@src/components/BaseComponent/pagination.util.js'
import { buildWhereQuery } from '@src/utils/filters/index.js'
import FILTER_KEYS from '@src/constants/filter-constants.js'
import { fullName } from '@src/utils/settings/tenant-user.util.js'

// States
const isLoading = ref(false)
const assignmentDetails = ref({})
const webLinksData = ref([])
const webLinksCounts = ref(0)
const studentsCounts = ref(0)
const isCheckListLoading = ref(false)
const isStudentsListLoading = ref(false)
const attachmentsData = ref([])
const studentsList = ref([])
const attachmentsCounts = ref(0)
const checkListData = ref([])
const checkListCounts = ref(0)
const isWebLinksLoading = ref(false)
const settings = ref({})
const webLinksTableHeaders = ['Title', 'Link']
const attachmentsTableHeaders = ['Title', 'Download']
const studentsTableHeaders = ['Name', 'Evaluator', 'Status']
// Computed

const assignmentId = computed(() => {
  return route.params.id
})

const showModal = computed(() => store.state.layout.showModal)
const showUiModal = computed(() => store.state.layout.showUiModal)
const currentClassScope = computed(() => store.state.layout.currentClassScope)
const currentSectionScope = computed(() => store.state.layout.currentSectionScope)

watch(
  () => currentClassScope.value,
  () => {
    router.push({ name: 'assignmens-view' })
  },
)
watch(
  () => currentSectionScope.value,
  () => {
    router.push({ name: 'assignmens-view' })
  },
)

// Mounted

onMounted(() => {
  setRightbarData()
  getDetails()
})
// Methods
const toggleAssignStudentModal = () => {
  store.dispatch('layout/setShowModal', !showModal.value)
  if (!showModal.value) getDetails()
}
const toggleAssignSectionModal = () => {
  store.dispatch('layout/setShowUiModal', !showUiModal.value)
  if (!showModal.value) getDetails()
}

const getSettingsStatus = (status) => {
  return status ? 'Yes' : 'No'
}
const setRightbarData = (count = 0) => {
  const rightBarContent = {
    header: {
      title: 'RB_A',
      buttons: [
        {
          title: 'RB_ATS',
          classes: ['primary-button-right'],
          action: { name: 'layout/setShowModal', payload: {} },
        },
        {
          title: 'RB_AT_SECT',
          classes: ['primary-button-right'],
          action: { name: 'layout/setShowUiModal', payload: {} },
        },
      ],
    },
  }
  store.dispatch('layout/setRightbarContent', rightBarContent)
}

const getDetails = async () => {
  isLoading.value = true
  let id = assignmentId.value
  getAssignmentDetails(id)
    .then((response) => {
      assignmentDetails.value = assignmentDetailsHandler(response?.data)
      webLinksCounts.value = response?.data?.assignment_web_links?.meta?.total_records
      webLinksData.value = response?.data?.assignment_web_links?.records
      checkListCounts.value = response?.data?.assignment_checklists?.meta?.total_records
      checkListData.value = response?.data?.assignment_checklists?.records
      setCheckListBullets()
      attachmentsCounts.value = response?.data?.assignment_attachments?.meta?.total_records
      attachmentsData.value = response?.data?.assignment_attachments?.records
      studentsCounts.value = response?.data?.student_assignments?.meta?.total_records
      studentsList.value = response?.data?.student_assignments?.records || []
      settings.value = response?.data?.assignment_submission_setting

      studentsList.value.forEach((studentAssignment) => {
        fullName(studentAssignment.student)
        fullName(studentAssignment.evaluator)
      })
    })
    .catch((error) => {
      store.commit('toast/NEW', {
        type: 'error',
        message: error?.message || 'Pardon us',
      })
    })
    .finally(() => {
      isLoading.value = false
    })
}

const setCheckListBullets = (skip = 0) => {
  checkListData.value.forEach((checkListItem, index) => {
    checkListItem['bullet'] = skip + index + 1
  })
}

const assignmentDetailsHandler = (assignment) => {
  return {
    title: assignment?.title,
    assignmentTypeTitle: assignment?.assignment_type?.title,
    subject: assignment?.subject?.title,
    marks: assignment?.marks,
    description: assignment?.description,
  }
}

const filterCheckList = (range) => {
  isCheckListLoading.value = true
  let paginationPayload = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
  let filtersPayload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'assignment_id', assignmentId.value),
    },
  }

  let payload = { ...paginationPayload, ...filtersPayload }

  filterAssignmentCheckList(payload).then((response) => {
    checkListCounts.value = response?.data?.meta?.total_records
    checkListData.value = response?.data?.records
    setCheckListBullets(paginationPayload.skip)
    isCheckListLoading.value = false
  })
}

const filterWebLinks = (range) => {
  isWebLinksLoading.value = true
  let paginationPayload = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
  let filtersPayload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'assignment_id', assignmentId.value),
    },
  }

  let payload = { ...paginationPayload, ...filtersPayload }

  filterAssignmentWebLinks(payload).then((response) => {
    webLinksData.value = response?.data?.records
    webLinksCounts.value = response?.data?.meta?.total_records
    isWebLinksLoading.value = false
  })
}

const filterStudentsList = (range) => {
  isStudentsListLoading.value = true
  let paginationPayload = paginationRangeHandler(range, GENERAL_CONSTANTS.MODAL_RECORD_LIMIT)
  let filtersPayload = {
    $where: {
      ...buildWhereQuery(FILTER_KEYS.EQUAL, 'assignment_id', assignmentId.value),
    },
  }

  let payload = { ...paginationPayload, ...filtersPayload }

  filterAssignmentStudents(payload).then((response) => {
    studentsList.value = response?.data?.records
    studentsList.value.forEach((studentAssignment) => {
      fullName(studentAssignment.student)
      fullName(studentAssignment.evaluator)
    })
    studentsCounts.value = response?.data?.meta?.total_records
    isStudentsListLoading.value = false
  })
}

const getAssignmentDetails = (payload) => store.dispatch('assignment/getAssignmentDetails', payload)
const filterAssignmentWebLinks = (payload) =>
  store.dispatch('assignment/filterAssignmentWebLinks', payload)
const filterAssignmentCheckList = (payload) =>
  store.dispatch('assignment/filterAssignmentCheckList', payload)
const filterAssignmentStudents = (payload) =>
  store.dispatch('assignment/filterAssignmentStudents', payload)
</script>
